
import gsap from 'gsap'

export default {
  name: 'Adv',
  props: {
    adverisings: {
      type: Array,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    gsap.fromTo(
      this.$refs.banners,
      { opacity: 0 },
      { opacity: 1, duration: 0.4 }
    )
  },
  methods: {
    getImage(advertising) {
      const image = this.horizontal
        ? advertising.cptAdvImageHorizontal
        : advertising.cptAdvImage
      return image
    },
  },
}

import gql from 'graphql-tag'
import { fragAuthorSummary } from '../fragments/author.js'

export const fragPostSummary = gql`
  ${fragAuthorSummary}
  fragment FragPostSummary on Post {
    postId
    id
    slug
    title
    date
    seo {
      metaDesc
    }
    featuredImage {
      node {
        sourceUrl
        srcSet
        sizes
        mediaDetails {
          height
          width
        }
      }
    }
    author {
      node {
        ...FragAuthorSummary
      }
    }
    categories {
      nodes {
        slug
      }
    }
  }
`

export const fragPostContent = gql`
  fragment FragPostContent on Post {
    title
    slug
    date
    postId
    id
    content(format: RENDERED)
    categories {
      nodes {
        slug
        categoryId
      }
    }
  }
`

export const fragPostSearch = gql`
  fragment FragPostSearch on Post {
    postId
    id
    slug
    title
    seo {
      metaDesc
    }
    featuredImage {
      node {
        sourceUrl
        srcSet
        sizes
        mediaDetails {
          height
          width
        }
      }
    }
  }
`

export const fragPostNextPrev = gql`
  fragment FragPostNextPrev on Post {
    link
    slug
    title
    seo {
      metaDesc
    }
    categories {
      nodes {
        slug
      }
    }
  }
`
